import {
  SET_LOADING_SHIPING_PACKAGE,
  SET_SHIPING_PACKAGE_ORDER,
  SET_SHIPING_PACKAGE_USER,
  FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS,
  FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL,
  CREATE_SHIPING_PACKAGE_ORDER_SUCCESS,
  CREATE_SHIPING_PACKAGE_ORDER_FAIL,
  VALIDATE_SHIPING_PACKAGE_NUMBER_SUCCESS,
  VALIDATE_SHIPING_PACKAGE_NUMBER_FAIL,
  CLEAR_SHIPING_PACKAGE,
  RESET_ALL_REDUCERS,
  CLEAR_VALIDATION_FIELDS,
  CREATE_RETURN_SHIPING_PACKAGE_ORDER_SUCCESS,
  CREATE_RETURN_SHIPING_PACKAGE_ORDER_FAIL,
} from "../actions/types"

const INIT_SHIPING_ORDER = () => {
  return {
    stationId: "",
    firstName: "",
    lastName: "",
    mobilePhone: "",
    packageNumber: "",
    orderType: 0,
    lockerNum: "",
    accountType: 0,
  }
}
const initSTATE = () => {
  return {
    activeFlow: "shipingPackage",
    order: INIT_SHIPING_ORDER(),
    isLoading: false,
    createdOrder: null,
    isNoAvailableLockersForService: false,
    lockersStatuses: [],
    isPackageNumberValid: false,
    isPackageNumberExist: false,
    isStationAllowed: true,
    isStationHaveEmpty: true,
    isError: false,
  }
}

const INIT_STATE = initSTATE()

const ShipingPackageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SHIPING_PACKAGE_ORDER:
      return {
        ...state,
        order: action.payload,
        createdOrder: null,
      }
    case CREATE_RETURN_SHIPING_PACKAGE_ORDER_SUCCESS:
    case CREATE_SHIPING_PACKAGE_ORDER_SUCCESS:
      return {
        ...state,
        order: INIT_SHIPING_ORDER(),
        isLoading: false,
        createdOrder: action.payload,
      }
    case CREATE_RETURN_SHIPING_PACKAGE_ORDER_FAIL:
    case CREATE_SHIPING_PACKAGE_ORDER_FAIL:
      return {
        ...state,
        createdOrder: null,
        isLoading: false,
      }
    case FETCH_AVAILABLE_LOCKERS_STATUSES_SUCCESS:
      let isNoAvailableLockersForService = false
      if (action.payload) {
        isNoAvailableLockersForService =
          action.payload.filter((l) => l.disabled === false).length <= 0
      }
      return {
        ...state,
        lockersStatuses: action.payload,
        isNoAvailableLockersForService: isNoAvailableLockersForService,
        isLoading: false,
      }
    case FETCH_AVAILABLE_LOCKERS_STATUSES_FAIL:
      return {
        ...state,
        lockersStatuses: [],
      }

    case SET_LOADING_SHIPING_PACKAGE:
      return {
        ...state,
        isLoading: true,
        createdOrder: null,
        isError: false,
      }
    case SET_SHIPING_PACKAGE_USER:
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload,
        },
        isLoading: false,
      }

    case VALIDATE_SHIPING_PACKAGE_NUMBER_SUCCESS:
      let order = INIT_SHIPING_ORDER()
      order.packageNumber = action.payload.packageNumber
      order.orderType = action.payload.orderType
      return {
        ...state,
        isPackageNumberValid: action.payload.isValid,
        isPackageNumberExist: action.payload.isExist,
        isStationAllowed: action.payload.isStationAllowed,
        isStationHaveEmpty: action.payload.isStationHaveEmpty,
        isLoading: false,
        isError: false,
        order,
      }
    case VALIDATE_SHIPING_PACKAGE_NUMBER_FAIL:
      return {
        ...state,
        isPackageNumberValid: action.payload.isValid,
        isPackageNumberExist: action.payload.isExist,
        isStationAllowed: action.payload.isStationAllowed,
        isStationHaveEmpty: action.payload.isStationHaveEmpty,
        isError: action.payload.isError,
        isLoading: false,
      }
    case CLEAR_VALIDATION_FIELDS:
      return {
        ...state,
        isPackageNumberValid: false,
        isPackageNumberExist: false,
        isStationAllowed: true,
        isStationHaveEmpty: true,
        isError: false,
        isLoading: false,
      }
    case CLEAR_SHIPING_PACKAGE:
      return initSTATE()
    case RESET_ALL_REDUCERS:
      return initSTATE()
    default:
      return state
  }
}

export default ShipingPackageReducer
