import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { FixedFooter, FixedHeader, LockerCells } from "../../../../reduxified"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../../Modals/WaitingForLockerLock"
import { providerManager } from "../../../../../providers/providerManager"
import {
  LogoutUser,
  reportFBEvent,
  logScreenEnterance,
  //setLockerToLockerDeliveryOrder,
  setShipingPackageOrder,
  createShipingPackageOrder,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
  setThankYouSubTitle,
  setShipingPackageLoading,
  clearShipingPackage,
  returnPackageOrder,
} from "../../../../../actions"
import "./style.css"

class ShipingPackageSelectLocker extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isClickWaiting: false,
      showYesNoInfoModal: false,
      isOpenedDoor: false,
      openedLocker: "",
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.props.logScreenEnterance("SHIPING_PACKAGE SELECT_LOCKER")
  }

  componentWillUnmount() {
    this.state = this.INIT_STATE
  }

  clearWait() {
    this.setState({ isClickWaiting: false })
  }

  async componentWillReceiveProps(newProps) {
    const {
      orderWasLoaded,
      openingErrorOccurred,
      openingDoorSuccess,
      see_you_again,
      order,
      lockersWithOpenStatusFromHw,
      stationId,
    } = newProps

    if (openingDoorSuccess) {
      if (this.state.isOpenedDoor) {
        return
      }

      const openedLocker = order && order.lockerNum ? order.lockerNum : ""
      this.setState({ isOpenedDoor: true, openedLocker })
      this.props.setShipingPackageLoading()
      const serviceName = newProps.history.location.state

      if (
        order.orderType === 21 ||
        order.orderType === 31 ||
        order.orderType === 33
      ) {
        console.log(
          "oneProject or ReturnsExternal returnPackageOrder  orderId:" +
            order.orderId +
            "  locationId:" +
            order.locationId
        )
        this.props.returnPackageOrder(
          order.orderId,
          order.locationId,
          stationId,
          order.lockerNum,
          lockersWithOpenStatusFromHw.toString() //optionalWrongDoor
        )
        return
      }
      if (providerManager.isProvider(serviceName)) {
        order.orderType = providerManager.orderType(serviceName)
        order.accountType = providerManager.accountType(serviceName)
      }
      this.props.createShipingPackageOrder({
        ...order,
        optionalWrongDoor: lockersWithOpenStatusFromHw.toString(),
      })
      this.props.clearShipingPackage()
      return
    }

    if (orderWasLoaded) {
      this.props.setThankYouSubTitle(see_you_again)
      console.log("ShipmentReceiveProps goto ThankYouScreen")
      this.props.history.push("/ThankYouScreen")
      return
    }

    if (openingErrorOccurred && !this.state.isOpenedDoor) {
      this.clearWait()
      this.setState({ showDoorOpenErrorModal: true })
    }
  }

  checkIsWaiting() {
    if (this.state.isCellClickWaiting) {
      return true
    }
    return false
  }

  isWaiting(num, wait) {
    if (wait && this.state.isCellClickWaiting) {
      console.log(".......isWaiting: " + wait)
      return true
    }

    this.setState({
      ...this.state,
      isCellClickWaiting: wait,
    })
    console.log(".......isWaiting: " + wait)
    return wait
  }

  onCellClick(cellNum, lockerData) {
    if (lockerData === undefined) {
      return
    }

    const { disabled } = lockerData
    if (disabled) {
      return
    }
    const { isLoading, isOrderConfirmed, stationId } = this.props
    if (isLoading || isOrderConfirmed) {
      return
    }

    if (this.checkIsWaiting()) return

    let { order, createdOrder } = this.props

    if (!order) {
      this.props.reportFBEvent(
        "SHIPING_PACKAGE SelectLocker",
        "The order is null. "
      )
      if (!createdOrder) {
        this.props.reportFBEvent(
          "SHIPING_PACKAGE SelectLocker",
          "The order and createdOrder is null. Redirect homepage."
        )
        console.log(
          "onCellClick - The order and createdOrder is null. Redirect homepage."
        )
        this.props.history.push("/")
      }
      return
    }

    this.isWaiting(cellNum, true)
    order.lockerNum = cellNum
    order.locationId = lockerData.lockerId
    console.log(
      "onCellClick - order.packageNumber: " +
        order.packageNumber +
        ", order.lockerNum" +
        order.lockerNum
    )
    const { stationPaymentVersion } = this.props
    console.log("order:")
    console.log(order)
    debugger
    this.props.setShipingPackageOrder(order)
    this.props.openAndListenToSpecificLockerLock(
      cellNum,
      undefined,
      stationId,
      order.packageNumber,
      2, //it's lockerStatus (2 means 'full', 3 means 'empty')
      stationPaymentVersion
    )
  }

  onDoorOpenErrModalClose() {
    this.setState({
      showDoorOpenErrorModal: false,
      clickedLocker: null,
      isCellClickWaiting: false,
    })
    this.props.resetLockersErrorsAttemptsOpened()
  }

  render() {
    const {
      blinkers,
      errLockers,
      opening_locker_number,
      waitingForLockerLockTitle,
      pls_close_door,
      err_while_opening_door,
      try_another_locker_or_contact_support,
      close_text,
      please_wait,
      openAttemptsNum,
      dirRtl,
      order,
      choose_locker,
    } = this.props

    const { showDoorOpenErrorModal, isOpenedDoor, openedLocker } = this.state

    let { lockerNum } = 11111
    if (order && order.lockerNum !== undefined && order.lockerNum != null) {
      lockerNum = order.lockerNum
    }
    return (
      <>
        <div className="ls-wrapper ">
          <FixedHeader buttonsFlag="unlockLockersScreen" />
          <div className="rent-locker-title">{choose_locker}</div>

          <div className="ddocs-inner-wrapper">
            <LockerCells
              onCellClick={this.onCellClick.bind(this)}
              blinkers={blinkers}
              errLockers={errLockers}
              hideInfo={true}
              shipingPackage={true}
            />
          </div>
          <FixedFooter history={this.props.history} />
        </div>

        <LoadingInfoModal
          dirRtl={dirRtl}
          show={openAttemptsNum > 0 && !showDoorOpenErrorModal}
          title={opening_locker_number.replace("{XYZ}", lockerNum)}
          text={please_wait}
        />

        <WaitingForLockerLock
          dirRtl={dirRtl}
          show={isOpenedDoor}
          title={waitingForLockerLockTitle.replace("{XYZ}", openedLocker)}
          subTitle={pls_close_door}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showDoorOpenErrorModal}
          title={err_while_opening_door}
          text={try_another_locker_or_contact_support.replace(
            "{XYZ}",
            lockerNum
          )}
          onModalCloseClick={() => this.onDoorOpenErrModalClose()}
          closeTrnsl={close_text}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  lockersCells,
  translate,
  bgService,
  shipingPackage,
  stationServiceTypes,
}) => {
  const {
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    recentClosedLockersArr,
    lockersWithOpenStatusFromHw,
    brokenLockersNumArr,
  } = lockersCells
  const {
    dirRtl,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    please_wait,
    opening_locker_number,
    on_order_collection_finish,
    pls_close_door,
    try_another_locker_or_contact_support,
    err_while_opening_door,
    close_text,
    choose_locker,
  } = translate

  const { stationPaymentVersion } = stationServiceTypes

  const waitingForLockerLockTitle = `${opening_locker_number}. ${on_order_collection_finish}`

  const { stationId } = bgService
  const { order, createdOrder, isLoading } = shipingPackage

  const { lockerNum } = order || {}
  const openingDoorSuccess = openedLockersArr.includes(lockerNum)
  const openingErrorOccurred = errorLockersArr.includes(lockerNum)
  const orderWasLoaded =
    createdOrder && recentClosedLockersArr.includes(createdOrder.lockerNum)
  const openAttemptsNum = openingAttempsMap[lockerNum] || 0
  const blinkers = !openingErrorOccurred ? { [lockerNum]: lockerNum } : {}
  const errLockers = openingErrorOccurred ? { [lockerNum]: lockerNum } : {}

  return {
    stationPaymentVersion,
    blinkers,
    errLockers,
    openingDoorSuccess,
    openingErrorOccurred,
    orderWasLoaded,
    openAttemptsNum,
    brokenLockersNumArr,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    opening_locker_number,
    stationId,
    order,
    createdOrder,
    dirRtl,
    please_wait,
    waitingForLockerLockTitle,
    pls_close_door,
    try_another_locker_or_contact_support,
    err_while_opening_door,
    close_text,
    lockersWithOpenStatusFromHw,
    isLoading,
    choose_locker,
  }
}

export default connect(mapStateToProps, {
  LogoutUser,
  reportFBEvent,
  logScreenEnterance,
  setShipingPackageOrder,
  createShipingPackageOrder,
  openAndListenToSpecificLockerLock,
  resetLockersErrorsAttemptsOpened,
  setThankYouSubTitle,
  setShipingPackageLoading,
  clearShipingPackage,
  returnPackageOrder,
})(withRouter(ShipingPackageSelectLocker))
