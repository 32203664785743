import React, { Component } from "react"
import Modal from "react-modal"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "./App.css"
import beep from "./assets/audio/beep.mp3"
import AutoLogouter from "./components/AutoLogouter"
import PingAPI from "./components/PingAPI"
import PingBGInternet from "./components/PingBGInternet"
import CollectOrdersScreen from "./components/screens/DriverScreens/CollectOrdersScreen"
import StationTasksScreen from "./components/screens/DriverScreens/StationTasksScreen"
import DriverMenuScreen from "./components/screens/DriverScreens/DriverMenuScreen"
import LoadCleanOrdersScreen from "./components/screens/DriverScreens/LoadCleanOrdersScreen"
import UnlockLockersScreen from "./components/screens/DriverScreens/UnlockLockersScreen"
import MaintenanceScreen from "./components/screens/MaintenanceScreen"
import AboutRental from "./components/screens/UserScreens/AboutRental"
import CollectOrderScreen from "./components/screens/UserScreens/CollectOrderScreen"
import DoneLoginScreen from "./components/screens/UserScreens/DoneLoginScreen"
import LockerToLockerDeliveryScreen from "./components/screens/UserScreens/LockerToLockerDeliveryScreen"
import AddresseeDetails from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/AddresseeDetails"
import LockerToLockerDeliveryInfo from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/LockerToLockerDeliveryInfo"
import LockerToLockerDeliveryMenu from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/LockerToLockerDeliveryMenu"
import PayOrder from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/PayOrder"
import SelectLocker from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/SelectLocker"
import SelectStation from "./components/screens/UserScreens/LockerToLockerDeliveryScreen/SelectStation"
import ServiceMenuScreen from "./components/screens/UserScreens/ServiceMenuScreen"
import LoginScreen from "./components/screens/UserScreens/LoginScreen"
import NewOrderScreen from "./components/screens/UserScreens/NewOrderScreen"
import NewPurchaseScreen from "./components/screens/UserScreens/NewPurchaseScreen"
import OrderItemsDepositScreen from "./components/screens/UserScreens/OrderItemsDepositScreen"
import RecreatePasswordScreen from "./components/screens/UserScreens/RecreatePasswordScreen"
import CompleteUserDetails from "./components/screens/UserScreens/RegistrationScreens/CompleteUserDetails"
import PasswordUpdateScreen from "./components/screens/UserScreens/RegistrationScreens/PasswordUpdateScreen"
import RentLockerMenuScreen from "./components/screens/UserScreens/RentLockerMenuScreen"
import RentLockerScreen from "./components/screens/UserScreens/RentLockerScreen"
import RestoreDeliveryCodeScreen from "./components/screens/UserScreens/RestoreDeliveryCodeScreen"
import ReviewOrderList from "./components/screens/UserScreens/ReviewOrderList"
//import ServiceMenuAppQRScreen from "./components/screens/UserScreens/ServiceMenuAppQRScreen"
import SignUpStep1Screen from "./components/screens/UserScreens/SignUpScreens/Step1Screen"
import SignUpStep2Screen from "./components/screens/UserScreens/SignUpScreens/Step2Screen"
import SignUpStep3Screen from "./components/screens/UserScreens/SignUpScreens/Step3Screen"
import SignUpStepVerifyScreen from "./components/screens/UserScreens/SignUpScreens/StepVerifyScreen"
import SimpleServiceMenuScreen from "./components/screens/UserScreens/SimpleServiceMenuScreen"
import SmsLoginScreen from "./components/screens/UserScreens/SmsLoginScreen"

import ThankYouScreen from "./components/screens/UserScreens/ThankYouScreen"
import UserLoginScreen from "./components/screens/UserScreens/UserLoginScreen"
import UserLoginWithDoneLoginScreen from "./components/screens/UserScreens/UserLoginWithDoneLoginScreen"
import UserMenuScreen from "./components/screens/UserScreens/UserMenuScreen"
import AppQRScreen from "./components/screens/UserScreens/AppQRScreen"
import ServiceDeliveryMenuScreen from "./components/screens/UserScreens/ServiceDeliveryMenuScreen"
import SenderDetails from "./components/screens/UserScreens/ShipingPackageScreen/SenderDetails"
import ScanBarcodeOnWaybill from "./components/screens/UserScreens/ShipingPackageScreen/ScanBarcodeOnWaybill"
import ShipingPackageSelectLocker from "./components/screens/UserScreens/ShipingPackageScreen/SelectLocker"
import TypeBarcodeManual from "./components/screens/UserScreens/ShipingPackageScreen/TypeBarcodeManual"
import DeliverySmsLogin from "./components/screens/UserScreens/ShipingPackageScreen/DeliverySmsLogin"
import ScanQRcodeForReturn from "./components/screens/UserScreens/ShipingPackageScreen/ScanQRcodeForReturn"

import Store from "./store"
import { initAxiosLogger, isProdEnv } from "./utils"
import LoginWithOTP from "./components/screens/DriverScreens/LoginWithOTP"

Modal.setAppElement("#root")
class App extends Component {
  constructor(props) {
    super(props)

    this.autoLogouterTrigger = null
  }

  componentWillMount() {
    initAxiosLogger()
  }

  componentDidMount() {
    document.addEventListener("click", () => {
      if (isProdEnv()) {
        new Audio(beep).play()
      }
      this.autoLogouterTrigger(new Date().getMilliseconds())
    })
  }

  triggerCallbackSetter(triggerFunc, cntx) {
    this.autoLogouterTrigger = (clickTime) => triggerFunc(cntx, clickTime)
  }

  render() {
    return (
      <Provider store={Store}>
        <Router>
          <PingAPI />
          <PingBGInternet />
          <AutoLogouter
            triggerCallbackSetter={(func, cntx) =>
              this.triggerCallbackSetter(func, cntx)
            }
          />
          <Switch>
            <Route path="/" exact component={ServiceMenuScreen} />
            <Route path="/ServiceMenuScreen" component={ServiceMenuScreen} />
            <Route path="/UserMenuScreen" component={UserMenuScreen} />
            <Route
              path="/ServiceDeliveryMenuScreen"
              component={ServiceDeliveryMenuScreen}
            />
            <Route
              path="/SimpleServiceMenuScreen"
              component={SimpleServiceMenuScreen}
            />
            <Route
              path="/RentLockerMenuScreen"
              component={RentLockerMenuScreen}
            />
            <Route path="/RentLockerScreen" component={RentLockerScreen} />
            <Route
              path="/LockerToLockerDeliveryScreen"
              component={LockerToLockerDeliveryScreen}
            >
              <Route
                path="/LockerToLockerDeliveryScreen/Info"
                component={LockerToLockerDeliveryInfo}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/SelectStation"
                component={SelectStation}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/AddresseeDetails"
                component={AddresseeDetails}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/PayOrder"
                component={PayOrder}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/SelectLocker"
                component={SelectLocker}
              />
              <Route
                path="/LockerToLockerDeliveryScreen/Menu"
                component={LockerToLockerDeliveryMenu}
              />
            </Route>
            <Route path="/SignUpStep1Screen" component={SignUpStep1Screen} />
            <Route path="/SignUpStep2Screen" component={SignUpStep2Screen} />
            <Route path="/SignUpStep3Screen" component={SignUpStep3Screen} />
            <Route
              path="/SignUpStepVerifyScreen"
              component={SignUpStepVerifyScreen}
            />
            <Route path="/NewOrderScreen" component={NewOrderScreen} />
            <Route path="/NewPurchaseScreen" component={NewPurchaseScreen} />
            <Route path="/AboutRental" component={AboutRental} />
            <Route
              path="/OrderItemsDepositScreen"
              component={OrderItemsDepositScreen}
            />
            <Route path="/CollectOrderScreen" component={CollectOrderScreen} />
            <Route path="/DriverMenuScreen" component={DriverMenuScreen} />
            <Route
              path="/UnlockLockersScreen"
              component={UnlockLockersScreen}
            />
            <Route
              path="/CollectOrdersScreen"
              component={CollectOrdersScreen}
            />
            <Route path="/StationTasksScreen" component={StationTasksScreen} />
            <Route
              path="/LoadCleanOrdersScreen"
              component={LoadCleanOrdersScreen}
            />
            <Route path="/ReviewOrderList" component={ReviewOrderList} />
            <Route path="/ThankYouScreen" component={ThankYouScreen} />
            <Route
              path="/RecreatePasswordScreen"
              component={RecreatePasswordScreen}
            />
            <Route path="/SmsLoginScreen" component={SmsLoginScreen} />
            <Route
              path="/RestoreDeliveryCodeScreen"
              component={RestoreDeliveryCodeScreen}
            />
            <Route path="/LoginScreen" component={LoginScreen} />
            <Route path="/UserLoginScreen" component={UserLoginScreen} />
            <Route
              path="/UserLoginWithDoneLoginScreen"
              component={UserLoginWithDoneLoginScreen}
            />
            <Route path="/DoneLoginScreen" component={DoneLoginScreen} />

            <Route path="/LoginWithOTP" component={LoginWithOTP} />

            <Route
              path="/CompleteUserDetails"
              component={CompleteUserDetails}
            />

            <Route
              path="/PasswordUpdateScreen"
              component={PasswordUpdateScreen}
            />
            <Route path="/AppQRScreen" component={AppQRScreen} />

            <Route
              path="/ShipingPackageScreen/SenderDetails"
              component={SenderDetails}
            />
            <Route
              path="/ShipingPackageScreen/ScanBarcodeOnWaybill"
              component={ScanBarcodeOnWaybill}
            />
            <Route
              path="/ShipingPackageScreen/TypeBarcodeManual"
              component={TypeBarcodeManual}
            />
            <Route
              path="/ShipingPackageScreen/DeliverySmsLogin"
              component={DeliverySmsLogin}
            />
            <Route
              path="/ShipingPackageScreen/SelectLocker"
              component={ShipingPackageSelectLocker}
            />
            <Route path="/MaintenanceScreen" component={MaintenanceScreen} />
            <Route
              path="/ShipingPackageScreen/ScanQRcodeForReturn"
              component={ScanQRcodeForReturn}
            />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

export default App
