import React, { Component } from "react"
import { connect } from "react-redux"
import {
  changeDisplayedLanguage,
  checkIfMobileAlreadyExists,
  fetchAllLockersStatuses,
  logScreenEnterance,
  onUserSignInClick,
  sendVerifyCode,
  clearLoginErrStatus,
  signInWithOTP,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportSuspiciousBrokenLockers,
  getAllOpenedLockers,
  resetAllReducers,
} from "../../../../actions"
import { validation } from "../../../../utils/Validations"
import { newlineText } from "../../../../utils/TextHelper"
import { DisEnbPendBtn, InputFiled } from "../../../common"
import DoneLoginButton from "../../../common/DoneLoginButton"
import VirtualKeyboard from "../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import YesNoInfoModal from "../../../Modals/YesNoInfoModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import MobileMessage from "../../../../assets/svg/mobile_message.svg"
import TimerClock from "../../../common/TimerClock"
import OtpInput from "react18-input-otp"

import "./style.scss"

class UserLoginWithDoneLoginScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      flow: {
        step1_EnterPhone: true,
        step2_EnterPwd: false,
        startTimerTime: null,
      },
      mobile: "",
      pwd: "",
      mobileRef: null,
      pwdRef: null,
      activeInput: null,
      activeInputName: null,
      isSubmitBtnDisabled: false,
      mobileErr: false,
      pwdErr: false,
      errorsTimeout: null,
      showErrorModal: false,
      showOTPErrorModal: false,
      isPasswordResetModalShown: false,
      runValidationsTimeOut: null,
      openedDoorModalCloseClicked: false,
      showPhoneNotFoundModal: false,
      showBaseErrorModal: false,
      inputPasswordType: "password",
      serviceName: "",
    }

    this.state = this.INIT_STATE
    this.mobileInput = React.createRef()
  }

  // componentWillMount() {

  // }

  componentDidMount() {
    this.setState(this.INIT_STATE)

    this.props.resetAllReducers()
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
    const { maxLockerNumber, brokenLockersNumArr } = this.props
    this.props.getAllOpenedLockers(maxLockerNumber, brokenLockersNumArr)

    this.runValidations()
    this.setState({ serviceName: this.props.history.location.state })
    this.props.logScreenEnterance("USER_LOGIN_WITH_DONE_LOGIN")
  }

  componentWillUnmount() {
    let { runValidationsTimeOut, errorsTimeout } = this.state
    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }
    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }
    this.setState(this.INIT_STATE)
  }

  componentWillReceiveProps(newProps) {
    const {
      authenticated,
      errTitle,
      errText,
      isPasswordTemporary,
      isRefillDetails,
      isError,
      errStatus,
      history,
    } = newProps

    this.setState({ showBaseErrorModal: isError, showOTPErrorModal: errStatus })

    if (
      this.state.flow.step1_EnterPhone &&
      newProps.mobileChecked &&
      !newProps.userMobileFree
    ) {
      let flow = this.state.flow
      flow.step1_EnterPhone = false
      flow.step2_EnterPwd = true
      this.setState({ flow, startTimerTime: Date.now(), disableButton: true })
      this.props.sendVerifyCode(this.state.mobile)
    }

    if (
      this.state.flow.step1_EnterPhone &&
      newProps.mobileChecked &&
      newProps.userMobileFree
    ) {
      this.setState({ showPhoneNotFoundModal: true })
    }

    if (this.state.flow.step1_EnterPhone) {
      this.setState({
        activeInput: this.state.mobileRef,
        activeInputName: "mobile",
      })
    }
    if (this.state.flow.step2_EnterPwd) {
      this.setState({ activeInput: this.state.pwdRef, activeInputName: "pwd" })
    }

    if (this.state.flow.step2_EnterPwd && authenticated) {
      const { serviceName } = this.state

      //if (isPasswordTemporary) {history.push("PasswordUpdateScreen", serviceName)}
      //if (isRefillDetails && !isPasswordTemporary) {history.push("CompleteUserDetails", serviceName)}

      if (isRefillDetails) {
        history.push("CompleteUserDetails", serviceName)
        return
      }

      if (serviceName === "laundry") {
        history.push("UserMenuScreen")
      }
      if (serviceName === "rent") {
        history.push("RentLockerMenuScreen")
      }
      if (serviceName === "lockerDelivery") {
        history.push("LockerToLockerDeliveryScreen/info")
      }
    }

    const { errTitle: oldErrTitle, errText: oldErrText } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      setTimeout(
        () =>
          this.setState({
            showErrorModal: true,
          }),
        250
      )
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      pwdErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  runValidations() {
    const { mobile, pwd } = this.state

    const isMobileNumValid = validation.Mobile05(mobile)
    //const isPwdValid = validation.Pwd(pwd)
    const isPwdValid = validation.VerifyCode(pwd)

    const isEmptyMobile = mobile.length < 1
    const isEmptyPwd = pwd.length < 1

    const isSubmitBtnDisabled = !isMobileNumValid || !isPwdValid || isEmptyPwd
    const isSubmitMobileBtnDisabled = !isMobileNumValid || isEmptyMobile
    const mobileErr = !isMobileNumValid && !isEmptyMobile
    const pwdErr = !isPwdValid && !isEmptyPwd

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        mobileErr,
        pwdErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            mobileErr,
            pwdErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }

    if (!isSubmitMobileBtnDisabled) {
      this.setState({
        isSubmitMobileBtnDisabled,
        mobileErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitMobileBtnDisabled,
            mobileErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onViewPasswordClick() {
    const { inputPasswordType } = this.state
    if (inputPasswordType === "password") {
      this.setState({ inputPasswordType: "text" })
    } else {
      this.setState({ inputPasswordType: "password" })
    }
  }

  onSignInClick() {
    const { mobile, pwd } = this.state
    //this.props.onUserSignInClick(mobile, pwd)
    this.props.signInWithOTP(mobile, pwd)
  }

  checkExistUser() {
    const { mobile } = this.state
    this.props.checkIfMobileAlreadyExists(mobile)
    //this.props.sendVerifyCode(mobile)
    //let flow = this.state.flow
    //flow.step1_EnterPhone = false
    //flow.step2_EnterPwd = true
    //this.onInputFocusChange("pwdRef", true)
    //this.setState({ flow, startTimerTime: Date.now(), disableButton: true })
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce(inputName, val)
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  filterEnterAndSubmit(e) {
    e.persist()
    if (e.key === "Enter") {
      setTimeout(() => {
        const { isSubmitBtnDisabled } = this.state

        if (!isSubmitBtnDisabled) {
          this.onSignInClick()
        }
      }, 700)
    }
  }

  onLangChange(lang) {
    const { stationId } = this.props
    this.props.changeDisplayedLanguage(lang)
  }

  render() {
    const {
      mobile,
      pwd,
      activeInputName,
      isSubmitBtnDisabled,
      isSubmitMobileBtnDisabled,
      mobileErr,
      pwdErr,
      showPhoneNotFoundModal,
      showErrorModal,
      showBaseErrorModal,
      inputPasswordType,
      showOTPErrorModal,
    } = this.state

    const {
      errTitle,
      errText,
      isLoadingRequest,
      dirRtl,
      cellphone_input_text_hint,
      continue_text,
      close_text,
      login_laundry,
      input_phone_title,
      input_psw_title,
      validationtext,
      phone_number_not_found,
      create_new_user_btn_text,
      isPasswordTemporary,
      input_temp_psw_title,
      base_error_title,
      base_error_text,
      history,
      //password_login_button_title,
      customer_otp_login_button_title,
      sms_sended_message,
      code_not_receved_text,
      send_new_code_btn,
      code_is_incorrect,
    } = this.props
    const { serviceName } = this.state

    const phone_number_not_found_text = phone_number_not_found.replace(
      "{mobile}",
      mobile
    )

    const { step1_EnterPhone, step2_EnterPwd } = this.state.flow

    let subTitle = input_phone_title
    let extraClass = " step1_EnterPhone"

    if (!step1_EnterPhone) {
      subTitle = isPasswordTemporary ? input_temp_psw_title : input_psw_title
      extraClass = " step2_EnterPwd"
    }

    return (
      <div className={"ls-wrapper lundry-login" + extraClass}>
        <FixedHeader
          title={login_laundry}
          subTitle={subTitle}
          mobile={mobile}
          buttonsFlag="userLoginScreen"
        />
        <div className="done-password-login-wrapper">
          {step1_EnterPhone && (
            <DoneLoginButton
              onClick={() =>
                this.props.history.push("/DoneLoginScreen", serviceName)
              }
            />
          )}
          {step2_EnterPwd && (
            <div className="sms-sended-message-wrapper">
              <div
                className="sms-sended-message-title"
                dir={dirRtl ? "rtl" : "ltr"}
              >
                {sms_sended_message}
              </div>
              <div>
                <img src={MobileMessage} />
              </div>
              <div className="timer-wrapper">
                <TimerClock
                  waitTime={60000}
                  startTime={this.state.startTimerTime}
                  onOverWaitTime={() => {
                    if (!this.state.disableButton) {
                      return
                    }
                    this.setState({ disableButton: false })
                  }}
                />
              </div>
              <div
                className="code-not-receved-wrapper"
                dir={dirRtl ? "rtl" : "ltr"}
              >
                <div
                  className="code-not-receved-text"
                  dir={dirRtl ? "rtl" : "ltr"}
                >
                  {code_not_receved_text}
                </div>
                <div
                  className={
                    "send-new-code-btn" +
                    (this.state.disableButton ? " disabled" : "")
                  }
                  disabled={this.state.disableButton}
                  onClick={() => {
                    if (this.state.disableButton) {
                      return
                    }
                    this.setState({
                      disableButton: true,
                      startTimerTime: Date.now(),
                    })
                    this.checkExistUser()
                  }}
                >
                  {send_new_code_btn}
                </div>
              </div>
            </div>
          )}

          <div className="laundry-container">
            <div className="enter-text" dir={dirRtl ? "rtl" : "ltr"}>
              {customer_otp_login_button_title.split("\n").map((str) => (
                <p key={str}>{str}</p>
              ))}
              {
                //password_login_button_title.split("\n").map((str) => (<p key={str}>{str}</p> ))
              }
            </div>

            <InputFiled
              show={true}
              value={mobile}
              onChange={(e) =>
                this.onInputFieldChange("mobile", e.target.value, e)
              }
              placeholder={cellphone_input_text_hint}
              icon="mini_phone"
              onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
              onFocusChanged={(isFocused) =>
                this.onInputFocusChange("mobileRef", isFocused)
              }
              displayErr={mobileErr}
              onKeyPress={(e) => this.filterEnterAndSubmit(e)}
              mandatory
              maxLength={10}
              type="tel"
              disabled={!step1_EnterPhone}
              isvalid={this.state.isMobileNumValid}
              validationtext={validationtext.mobile}
              autoFocus
            />
            <DisEnbPendBtn
              show={step1_EnterPhone}
              onClick={() => this.checkExistUser()}
              isDisabled={isSubmitMobileBtnDisabled}
              isPending={isLoadingRequest}
              extraClass="login-btn"
            >
              {continue_text}
            </DisEnbPendBtn>

            <div
              className="ls-frame-inner-wrapper"
              dir={dirRtl ? "rtl" : "lrt"}
            >
              {step2_EnterPwd && (
                <OtpInput
                  value={pwd}
                  numInputs={5}
                  inputStyle="otp-input"
                  isInputNum
                  onChange={(val) => {
                    this.setState({ pwd: val })
                    this.runValidationsWithTimoutDebounce("pwd", val)
                  }}
                  //onRefReady={(ref) => this.onRefReady("pwdRef", ref)}
                  //onFocusChanged={(isFocused) => this.onInputFocusChange("pwdRef", isFocused)}
                  maxLength={5}
                  shouldAutoFocus={true}
                />
              )}
            </div>
            <DisEnbPendBtn
              show={step2_EnterPwd}
              onClick={(e) => this.onSignInClick()}
              isDisabled={isSubmitBtnDisabled}
              isPending={isLoadingRequest}
              extraClass="login-btn"
            >
              {continue_text}
            </DisEnbPendBtn>

            <VirtualKeyboard
              currentValue={this.state[activeInputName]}
              onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
              show={true}
              lang="mobile"
              closeOnEnter
              blockLangSwitch={inputPasswordType}
            />
          </div>
        </div>

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal && errTitle && errText}
          title={errTitle}
          text={errText}
          onModalCloseClick={() => this.setState({ showErrorModal: false })}
          closeTrnsl={close_text}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showBaseErrorModal}
          title={base_error_title}
          text={base_error_text}
          onModalCloseClick={() => this.setState({ showBaseErrorModal: false })}
          closeTrnsl={close_text}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={this.props.errStatus}
          text={code_is_incorrect}
          onModalCloseClick={() => {
            this.setState({ pwd: "" })
            this.props.clearLoginErrStatus()
          }}
          closeTrnsl={close_text}
        />

        <YesNoInfoModal
          show={showPhoneNotFoundModal}
          title={""}
          text={phone_number_not_found_text}
          onModalOkClick={(e) => this.props.history.push("SignUpStep1Screen")}
          onModalCancelClick={(e) =>
            this.setState({ showPhoneNotFoundModal: false })
          }
          okTransl={create_new_user_btn_text}
          cancelTransl={close_text}
          dirRtl={this.props.dirRtl}
        />
        <FixedFooter history={history} />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  translate,
  stationServiceTypes,
  registration,
  lockersCells,
}) => {
  const { stationId } = bgService
  const { maxLockerNumber, brokenLockersNumArr } = lockersCells
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    isPasswordTemporary,
    isRefillDetails,
    userId,
    isError,
    errStatus,
  } = userLogin
  const {
    dirRtl,
    langName,
    welcome_title_text,
    cellphone_input_text_hint,
    register_btn_text,
    password_input_text_hint,
    continue_text,
    password_for_delivery,
    pick_const_pwd_msg_title,
    pick_const_pwd_msg_body,
    close_text,
    back_btn_text,
    system_updgrade_err_msg,
    login_laundry,
    input_phone_title,
    validation_empty_text,
    validation_mobile_text,
    validation_password_text,
    validation_name_text,
    validation_email_text,
    validation_email_verification_text,
    input_psw_title,
    input_temp_psw_title,
    phone_number_not_found,
    create_new_user_btn_text,
    base_error_title,
    base_error_text,
    password_login_button_title,
    customer_otp_login_button_title,
    sms_sended_message,
    code_not_receved_text,
    send_new_code_btn,
    code_is_incorrect,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    mobile: validation_mobile_text,
    password: validation_password_text,
    name: validation_name_text,
    email: validation_email_text,
    emailVerification: validation_email_verification_text,
  }

  const { isDeliverySimulationStation } = stationServiceTypes
  const { mobileChecked, userMobileFree } = registration
  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    isPasswordTemporary,
    userId,
    stationId,
    isError,
    dirRtl,
    langName,
    maxLockerNumber,
    brokenLockersNumArr,
    welcome_title_text,
    cellphone_input_text_hint,
    password_input_text_hint,
    continue_text,
    password_for_delivery,
    register_btn_text,
    pick_const_pwd_msg_title,
    pick_const_pwd_msg_body,
    back_btn_text,
    close_text,
    system_updgrade_err_msg,
    login_laundry,
    input_phone_title,
    validationtext,
    input_psw_title,
    input_temp_psw_title,
    phone_number_not_found,
    create_new_user_btn_text,
    base_error_title,
    base_error_text,
    isDeliverySimulationStation,
    mobileChecked,
    userMobileFree,
    isRefillDetails,
    password_login_button_title,
    customer_otp_login_button_title,
    sms_sended_message,
    code_not_receved_text,
    send_new_code_btn,
    errStatus,
    code_is_incorrect,
  }
}

export default connect(mapStateToProps, {
  onUserSignInClick,
  sendVerifyCode,
  signInWithOTP,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  resetAllReducers,
  getAllOpenedLockers,
  changeDisplayedLanguage,
  fetchAllLockersStatuses,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  checkIfMobileAlreadyExists,
  clearLoginErrStatus,
})(UserLoginWithDoneLoginScreen)
