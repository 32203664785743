import React, { Component } from "react"
import { connect } from "react-redux"
import {
  logScreenEnterance,
  setShipingPackageLoading,
  validateShipingPackageNumber,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportFBEvent,
  clearShipingPackage,
} from "../../../../../actions"
import { DisEnbPendBtn, InputFiled } from "../../../../common"
import VirtualKeyboard from "../../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import WarningInfoModal from "../../../../Modals/WarningInfoModal"
import { FixedFooter, FixedHeader } from "../../../../reduxified"
import BarcodeRectangle from "../../../../../assets/images/barcode_rectangle.png"
import BarcodeRounded from "../../../../../assets/images/barcode_rounded.png"
import RayShadow from "../../../../../assets/svg/ray_shadow.svg"

import ExelotBarcodeManual from "../../../../../assets/images/exelot_barcode_manual.png"
import ExelotBarcodeManualIcon from "../../../../../assets/images/exelot_barcode_manual_icon.png"
import ExelotRay from "../../../../../assets/images/exelot_ray_manual.png"

import { providerManager } from "../../../../../providers/providerManager"
import "./style.scss"

class TypeBarcodeManual extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      waybill: "",
      waybillRef: null,

      activeInput: null,
      activeInputName: null,
      isSubmitBtnDisabled: false,
      waybillErr: false,

      errorsTimeout: null,
      runValidationsTimeOut: null,
      openedDoorModalCloseClicked: false,
      serviceName: "",
      showNotValidBarcodeErrorModal: false,
      showNotAllowedStationErrorModal: false,
      showNoEmptyLockerErrorModal: false,
      waybill_to_send: "",
      packageNumber: null,
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.setState(this.INIT_STATE)
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
    this.props.logScreenEnterance("TYPE_MANUAL_WAYBILL_SCREEN")
    this.runValidations()
    this.setState({
      serviceName: this.props.history.location.state,
      activeInput: this.state.waybillRef,
      activeInputName: "waybill",
    })
  }

  componentWillUnmount() {
    let { runValidationsTimeOut, errorsTimeout } = this.state
    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }
    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }
    this.setState(this.INIT_STATE)
  }

  componentWillReceiveProps(newProps) {
    const {
      stationId,
      order,
      isPackageNumberValid,
      isPackageNumberExist,
      isStationAllowed,
      isStationHaveEmpty,
      isLoading,
      history,
    } = newProps

    const { serviceName } = this.state

    if (!order || !stationId) {
      this.props.reportFBEvent(
        "SenderDetailsScreen",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("/")
    }

    if (isLoading) {
      console.log(" ---  isLoading:" + isLoading)
      return
    }

    if (
      isPackageNumberExist ||
      (!isPackageNumberValid && !isLoading && this.state.packageNumber)
    ) {
      console.log(
        " ---  isPackageNumberExist:" +
          isPackageNumberExist +
          " ---  isPackageNumberValid:" +
          isPackageNumberValid
      )
      this.setState({ showNotValidBarcodeErrorModal: true })
      return
    }

    if (
      !isPackageNumberExist &&
      isPackageNumberValid &&
      !isLoading &&
      this.state.packageNumber &&
      !isStationAllowed
    ) {
      console.log(" ---  isStationAllowed:" + isStationAllowed)
      this.setState({ showNotAllowedStationErrorModal: true })

      return
    }

    if (!isStationHaveEmpty) {
      console.log(" ---  isStationHaveEmpty:" + isStationHaveEmpty)
      this.setState({ showNoEmptyLockerErrorModal: true })
      return
    }

    console.log(
      " ---  GO next - SenderDetails:" +
        (isPackageNumberValid &&
          !isPackageNumberExist &&
          !isLoading &&
          this.state.packageNumber &&
          isStationAllowed &&
          isStationHaveEmpty)
    )

    if (
      isPackageNumberValid &&
      !isPackageNumberExist &&
      !isLoading &&
      this.state.packageNumber &&
      isStationAllowed &&
      isStationHaveEmpty
    ) {
      //order.packageNumber = this.state.packageNumber
      //this.props.setShipingPackageOrder(order)
      console.log(order)
      history.push("/ShipingPackageScreen/SenderDetails", serviceName)
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout, serviceName } = this.state

    const clearValue = text.split(" ").join("")
    const formattedWaybill = providerManager.formatWaybill(
      serviceName,
      clearValue
    )

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: formattedWaybill,
      waybill_to_send: clearValue,
      waybillErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  runValidations() {
    const { waybill_to_send, serviceName } = this.state

    const waybill = waybill_to_send
    const isWaybillNumValid = providerManager.validatePackageBarcode(
      serviceName,
      waybill
    )

    const isEmptyWaybill = waybill.length < 1

    const isContinueBtnDisabled = !isWaybillNumValid || isEmptyWaybill
    const waybillErr = !isWaybillNumValid && !isEmptyWaybill

    if (!isContinueBtnDisabled) {
      this.setState({
        isContinueBtnDisabled,
        waybillErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isContinueBtnDisabled,
            waybillErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onBarcodeInputClick() {
    let { waybill, isContinueBtnDisabled, serviceName } = this.state
    waybill = waybill.split(" ").join("")
    if (isContinueBtnDisabled || this.props.isLoading) {
      return
    }
    this.setState({ packageNumber: null })
    const isValidPackageBarcode = providerManager.validatePackageBarcode(
      serviceName,
      waybill
    )

    if (!isValidPackageBarcode) {
      this.setState({ showNotValidBarcodeErrorModal: true })
      return
    }

    this.setState({
      showScanError: false,
      packageNumber: waybill,
    })

    let orderType = providerManager.orderType(serviceName)

    this.props.setShipingPackageLoading()

    this.props.validateShipingPackageNumber(
      waybill,
      orderType,
      this.props.stationNumber
    )
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    let { serviceName } = this.state
    const waybill_to_send = val.split(" ").join("")
    const formatedVal = providerManager.formatWaybill(
      serviceName,
      waybill_to_send
    )
    this.setState({ [inputName]: formatedVal, waybill_to_send })
    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 300)

    this.setState({ runValidationsTimeOut })
  }

  filterEnterAndSubmit(e) {
    e.persist()
    if (e.key === "Enter") {
      setTimeout(() => {
        const { isContinueBtnDisabled } = this.state

        if (!isContinueBtnDisabled) {
          this.onBarcodeInputClick()
        }
      }, 700)
    }
  }

  render() {
    const {
      waybill,
      activeInputName,
      isContinueBtnDisabled,
      waybillErr,
      showNotValidBarcodeErrorModal,
      showNotAllowedStationErrorModal,
      showNoEmptyLockerErrorModal,
      serviceName,
    } = this.state

    const {
      isLoading,
      dirRtl,
      waybill_input_text_hint,
      waybill_input_text_hint_hfd,
      continue_text,
      login_laundry,
      validationtext,
      history,
      type_in_the_waybill_number,
      type_in_the_waybill_number_hfd,
      additional_scan_try,
      not_valid_barcode_error_title,
      not_valid_barcode_error_text,
      barcode_exist_error_title,
      barcode_exist_error_text,
      isPackageNumberExist,
      not_allowed_station_title,
      not_allowed_station_error_text,
      exit_btn_text,
      all_lockers_full_err_title,
      all_lockers_full_err_body,
      close_text,
    } = this.props

    let subTitle =
      serviceName === "hfd"
        ? type_in_the_waybill_number_hfd
        : type_in_the_waybill_number
    let input_text_hint =
      serviceName === "hfd"
        ? waybill_input_text_hint_hfd
        : waybill_input_text_hint
    console.log("serviceName:" + serviceName)
    return (
      <div className={"ls-wrapper manual-waybill"}>
        <FixedHeader
          title={login_laundry}
          subTitle={subTitle}
          mobile={waybill}
          buttonsFlag="manualWaybillScreen"
        />

        <div className={"manual-waybill-container " + (dirRtl ? "rtl" : "ltr")}>
          <div className="title-text">
            <h1>{subTitle}</h1>
          </div>

          {providerManager.renderLogo(serviceName)}

          <InputFiled
            show={true}
            value={waybill}
            onChange={(e) =>
              this.onInputFieldChange("waybill", e.target.value, e)
            }
            placeholder={input_text_hint}
            onRefReady={(ref) => this.onRefReady("waybillRef", ref)}
            onFocusChanged={(isFocused) =>
              this.onInputFocusChange("waybillRef", isFocused)
            }
            displayErr={waybillErr}
            onKeyPress={(e) => this.filterEnterAndSubmit(e)}
            mandatory
            maxLength={12}
            type="text"
            isvalid={this.state.isWaybillNumValid}
            validationtext={validationtext.waybill}
            autoFocus
            disabled={isLoading}
          />
          {serviceName === "dhl" && (
            <div className="underline-wrapper dhl">
              <div className="underline"></div>
            </div>
          )}

          {serviceName === "exelot" && (
            <div className="underline-wrapper exelot">
              <div className="underline"></div>
            </div>
          )}
          <DisEnbPendBtn
            show={true}
            onClick={() => this.onBarcodeInputClick()}
            isDisabled={isContinueBtnDisabled}
            isPending={isLoading}
            extraClass="continue-btn"
          >
            {continue_text}
          </DisEnbPendBtn>

          {serviceName === "dhl" && (
            <div className="icon-info-container dhl">
              <img
                className="rounded-barcode-icon"
                src={BarcodeRounded}
                alt="Rounded Barcode"
              />

              <img
                className="rectangle-barcode-icon"
                src={BarcodeRectangle}
                alt="Rectangle Barcode"
              />
              <img
                className="ray-shadow-icon"
                src={RayShadow}
                alt="ray shadow icon"
              />
            </div>
          )}
          {serviceName === "hfd" && (
            <div className="icon-info-container dhl">
              <img
                className="rounded-barcode-icon"
                src={BarcodeRounded}
                alt="Rounded Barcode"
              />

              <img
                className="rectangle-barcode-icon"
                src={BarcodeRectangle}
                alt="Rectangle Barcode"
              />
              <img
                className="ray-shadow-icon"
                src={RayShadow}
                alt="ray shadow icon"
              />
            </div>
          )}
          {serviceName === "exelot" && (
            <div className="icon-info-container exelot">
              <div className="icon-info-inner-container">
                <img
                  className="exelot-barcode"
                  src={ExelotBarcodeManual}
                  alt="Exelot Barcode"
                />
                <img
                  className="exelot-barcode-icon"
                  src={ExelotBarcodeManualIcon}
                  alt="Exelot Barcode Icon"
                />
                <img className="exelot-ray" src={ExelotRay} alt="ray icon" />
              </div>
            </div>
          )}
        </div>

        <VirtualKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={true}
          lang={serviceName === "dhl" ? "mobile" : "en"}
          maxLength={12}
          closeOnEnter
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showNotValidBarcodeErrorModal}
          title={
            isPackageNumberExist
              ? barcode_exist_error_title
              : not_valid_barcode_error_title
          }
          text={
            isPackageNumberExist
              ? barcode_exist_error_text
              : not_valid_barcode_error_text
          }
          onModalCloseClick={() =>
            this.setState({ showNotValidBarcodeErrorModal: false })
          }
          closeTrnsl={additional_scan_try}
          className="shiping-barcode-scan"
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showNotAllowedStationErrorModal}
          title={not_allowed_station_title}
          text={not_allowed_station_error_text}
          onModalCloseClick={() => {
            this.setState({ showNotAllowedStationErrorModal: false })
            this.props.history.push("/")
          }}
          closeTrnsl={exit_btn_text}
          className="shiping-barcode-scan"
        />
        <WarningInfoModal
          show={showNoEmptyLockerErrorModal}
          title={all_lockers_full_err_title}
          text={all_lockers_full_err_body}
          onModalCloseClick={() => {
            this.setState({ showNoEmptyLockerErrorModal: false })
            this.props.history.push("/")
          }}
          btnText={close_text}
        />
        <FixedFooter history={history} />
      </div>
    )
  }
}

const mapStateToProps = ({ bgService, translate, shipingPackage }) => {
  const { stationId, stationNumber } = bgService
  const {
    order,
    isPackageNumberValid,
    isPackageNumberExist,
    isLoading,
    isStationAllowed,
    isStationHaveEmpty,
  } = shipingPackage
  const {
    dirRtl,
    waybill_input_text_hint,
    waybill_input_text_hint_hfd,
    continue_text,
    login_laundry,
    validation_empty_text,
    validation_waybill_text,
    type_in_the_waybill_number,
    type_in_the_waybill_number_hfd,
    not_valid_barcode_error_title,
    not_valid_barcode_error_text,
    additional_scan_try,
    barcode_exist_error_title,
    barcode_exist_error_text,
    not_allowed_station_title,
    not_allowed_station_error_text,
    exit_btn_text,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    close_text,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    waybill: validation_waybill_text,
  }

  return {
    stationId,
    stationNumber,
    dirRtl,
    waybill_input_text_hint,
    waybill_input_text_hint_hfd,
    continue_text,
    login_laundry,
    validationtext,
    type_in_the_waybill_number,
    type_in_the_waybill_number_hfd,
    order,
    isPackageNumberValid,
    isPackageNumberExist,
    isStationAllowed,
    isStationHaveEmpty,
    isLoading,
    not_valid_barcode_error_title,
    not_valid_barcode_error_text,
    additional_scan_try,
    barcode_exist_error_title,
    barcode_exist_error_text,
    not_allowed_station_title,
    not_allowed_station_error_text,
    exit_btn_text,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    close_text,
  }
}

export default connect(mapStateToProps, {
  readStationIdFromBgService,
  readBgServiceConfiguration,
  logScreenEnterance,
  setShipingPackageLoading,
  validateShipingPackageNumber,
  clearShipingPackage,
  reportFBEvent,
})(TypeBarcodeManual)
